<template>
  <div class="row">
    <div class="col-12 col-md-3 col-lg-2">
      <nav
        class="
          navbar navbar-vertical
          fixed-left
          navbar-expand-md navbar-light
          page-inner-nav-container
          nice-scroll
          z-100
        "
        style="position: relative; border-width: 0; background: none"
      >
        <ul class="navbar-nav border-none">
          <li
            class="nav-item"
            :class="{
              'u-display-none': link.patientHide && (!user || user.isPatient),
            }"
            v-for="link in nav"
            :key="link.to"
          >
            <router-link
              v-if="!link.patientHide || !user || !user.isPatient"
              :to="getLink(link.to)"
              class="nav-link"
              :class="$route.name == link.to ? `active` : ``"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col">
      <router-view class="mt-4"></router-view>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      nav: [
        {
          label: "Budget",
          to: "patients.budget",
          icon: "tags",
          patientHide: true,
        },
        {
          label: "Budget Charts",
          to: "patients.budget-charts",
          icon: "chart-bar",
          patientHide: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getLink(to) {
      return {
        name: to,
        params: {
          id: this.$route.params.id,
        },
      };
    },
  },
};
</script>
